<template>
    <div class="content d-flex">
        <div class="aside-right ">
            <!----LOGIN------>
            <div class="card" v-if="loginCard">
                <div class="card-body" @click="error = ''; messageref = ''">
                    <h4 class="card-title">Log In to Telli-pipeline</h4>
                    <div class="fields">
                        <label>Username</label>
                        <InputText type="text" v-model="username" placeholder="Username" inputStyle="width: 100%"/>
                        <label class="mt-4">Password</label>
                        <Password class='w-100' v-model="password" placeholder="Password" toggleMask :feedback="false" inputStyle="width: 100%" />
                        <span>{{error.message}}<span v-if="error.message !='User is not confirmed.' ">{{messageref}}</span></span>
                        <router-link :to="'/confirmsignup/'+username"><button v-if="error.message == 'User is not confirmed.'"   style="cursor: pointer;">{{messageref}}</button></router-link>

                    </div>
                    <div class="forgot-pass my-3 text-left">
                       <h2 class="navigation-link">Forgot your password?<router-link to="/forgetpassword"><b>Reset password</b></router-link></h2> 
                    </div>
                    <div class="login-btn">
                        <router-link to="/signup"><h2 class="navigation-link">No account?<b>Create account</b></h2></router-link> 
                        <Button label="Login" class="p-button-primary" @click="login()"/>
                    </div>
                </div>
            </div>           
        </div>
        <div class="loader" id="propertiesLoader" v-if = "loader">
            <ProgressSpinner style="width:50px;height:50px" />
        </div>
    </div>    
</template>
<script>
import { mapActions } from 'vuex';
import { Auth } from "aws-amplify";
export default {
    name:"Login",
    data(){
        return {
            loader      : false,
            username    : "",
            password    : "",
            email       : "",
            error       : "",
            messageref  : '',
            loginCard      : true 
        }
    },
    methods: {
        ...mapActions({
            loginVue            : "auth/login",
        }),
        async login() {
            this.loader = true;
            try {
                await this.loginVue({
                    username: this.username,
                    password: this.password,
                });
                let userCredentials = await Auth.currentSession();
                this.$router.push({ name: 'Loading', params :{ token : userCredentials.getAccessToken().getJwtToken() }});
            } catch (error) {
                this.loader = false;
                if(error.message == 'User is not confirmed.'){
                    this.error = error;
                    this.messageref = ' Please Confirm User';
                } else if(error.message == 'Custom auth lambda trigger is not configured for the user pool.'){
                    this.error = {message:'User not exsist '}
                    this.messageref = ' Please Sign Up';
                } else if(error.message == 'User does not exist.'){
                    this.error = error;
                    this.messageref = ' Please Sign Up';
                } else if(error.message == 'User is not confirmed.'){
                    this.error = error;
                    this.messageref = ' Please Confirm';
                } else{
                    this.error = error;
                }
            }
        },
        confirmSignUp(){
            this.$router.push({name : 'ConfirmSignup', params: this.username})
        }

    }
}
</script>
<style lang="scss" scoped>
@import "Auth.scss";
</style>